/* FavoriteCheckbox.css */

.favorite-checkbox {
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
  }
  
  .favorite-label {
    /* display: flex; */
    align-items: center;
    cursor: pointer;
  }
  
  .favorite-checkbox-input {
    display: none;
  }
  
  .favorite-icon {
    margin-right: 5px;
    font-size: 1.2em;
  }
  

  @media screen and (min-width:320px) and (max-width:481px) {
    .favorite-checkbox {
      font-family: Arial, sans-serif;
      margin-bottom: 10px; 
      margin-left: 0%;
      width: 108%;
  }
    
  }