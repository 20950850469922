.sidebar {
    /* width: 250px; */
    background-color: #f0f0f0;
    /* padding: 20px; */
    box-sizing: border-box;
    text-align: justify;
  }
  
  .arrow-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .arrow-buttons svg {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .sidebar_manu{
    cursor: pointer;
  }
  