.menu {
    /* background-color: #7c3a8e; */
    margin-top: 30px;
    height: 65px;
    color: #000;
    border-top: 1px solid #7c3a8e;
  } 
  
  .menu-list {
    list-style-type: none;
    padding: 17px;
    margin: 0;
  }
  
  .menu-list > li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .menu-list > li > a {
    color: #000;
    text-decoration: none;
    padding: 10px;
  }
  
  .dropdown {
    position: relative;
    font-size: 12px;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .dropdown:hover .dropdown-menu {
    display: block;
    text-align: left;
  }
  
  .dropdown-menu > li > a {
    display: block;
    padding: 10px;
    color: #333;
    text-decoration: none;
  }
  
  .dropdown-menu > li > a:hover {
    background-color: #f4f4f4;
  }


  @media screen and (min-width:320px) and (max-width:481px) {
  .menu {
    height: auto;
    margin-top: 30px;
    width: 100%;
    text-align: left;
  }
  
}